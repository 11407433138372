<template>
    <div class="app-container">
        <div class="filter-container">
            <el-input class="filter-item" v-model="listQuery.order_no" placeholder="订单号" style="width: 220px;" clearable/>

            <el-input class="filter-item" v-model="listQuery.u_no" placeholder="专属号" style="width: 220px;" clearable/>

            <el-input class="filter-item" v-model="listQuery.phone" placeholder="手机号" style="width: 220px;" clearable/>

            <el-select class="filter-item" v-model="listQuery.put_meal_type" placeholder="投餐类型" style="width: 140px" clearable>
                <el-option label="订单号" :value="1"></el-option>
                <el-option label="专属号" :value="2"></el-option>
                <el-option label="手机号" :value="3"></el-option>
            </el-select>

            <el-input class="filter-item" v-model="listQuery.device_name" placeholder="设备名称" style="width: 220px;" clearable/>

            <el-select class="filter-item" v-model="listQuery.state" placeholder="投餐订单状态" style="width: 140px" clearable>
                <el-option label="已投餐" :value="1"></el-option>
                <el-option label="已确认投餐" :value="2"></el-option>
                <el-option label="已取餐" :value="3"></el-option>
                <el-option label="取消投餐" :value="4"></el-option>
            </el-select>

            <el-date-picker v-model="listQuery.start_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" placeholder="开始日期" clearable></el-date-picker>

            <el-date-picker v-model="listQuery.end_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59" placeholder="截止日期" clearable></el-date-picker>

            <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
        </div>
        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column align="center" label="ID" width="80">
                <template slot-scope="scope">
                    <!-- {{ scope.$index }} -->
                    {{ scope.row.id }}
                </template>
            </el-table-column>

            <el-table-column label="用户" min-width="110" align="center">
                <template slot-scope="scope">
                    <div v-if="scope.row.user" style="display: flex;flex-direction:column;align-items:center;text-align:center;">
                        <img v-if="scope.row.user.avatar" :src="scope.row.user.avatar" class="user-avatar">
                        <span>{{ scope.row.user.nickname }}</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="订单号" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.order_no }}
                </template>
            </el-table-column>

            <el-table-column label="专属号" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.u_no }}
                </template>
            </el-table-column>

            <el-table-column label="手机号" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.phone }}
                </template>
            </el-table-column>

            <el-table-column label="餐柜订单号" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.sideboard_order_no }}
                </template>
            </el-table-column>

            <el-table-column label="AUV订单id" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.order_id }}
                </template>
            </el-table-column>

            <el-table-column label="投餐类型" min-width="80" align="center">
                <template slot-scope="scope">
                    <el-tag type="primary" v-if="scope.row.put_meal_type === 1">订单号</el-tag>
                    <el-tag type="success" v-else-if="scope.row.put_meal_type === 2">专属号</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.put_meal_type === 3">手机号</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="设备id" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.device_id }}
                </template>
            </el-table-column>

            <el-table-column label="设备名称" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.device_name }}
                </template>
            </el-table-column>

            <el-table-column label="格子号" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.cell_id }}
                </template>
            </el-table-column>

            <el-table-column label="格子号别名" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.cell_alias }}
                </template>
            </el-table-column>

            <el-table-column label="取餐码" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.take_code }}
                </template>
            </el-table-column>

            <el-table-column label="投餐员" min-width="110" align="center">
                <template slot-scope="scope">
                    <div style="display: flex;flex-direction:column;align-items:center;text-align:center;">
                        <span>{{ scope.row.sendman.name }}</span>
                        <span>{{ scope.row.sendman.phone }}</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="投餐订单状态" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag type="primary" v-if="scope.row.state === 1">已投餐</el-tag>
                    <el-tag type="warning" v-else-if="scope.row.state === 2">已确认投餐</el-tag>
                    <el-tag type="success" v-else-if="scope.row.state === 3">已取餐</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.state === 4">取消投餐</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="投餐时间" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.put_meal_time }}
                </template>
            </el-table-column>

            <el-table-column label="发送短信号码" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.sms_phone }}
                </template>
            </el-table-column>

            <el-table-column label="发送短信状态" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag type="info" v-if="scope.row.sms_status === 0">未发送</el-tag>
                    <el-tag type="success" v-else-if="scope.row.sms_status === 1">发送成功</el-tag>
                    <el-tag type="danger" v-else-if="scope.row.sms_status === 2">发送失败</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="短信接收状态" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.sms_receive_status }}
                </template>
            </el-table-column>

            <el-table-column label="取餐时间" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.take_meal_time }}
                </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right" align="center" width="110" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <div>
                        <el-button v-if="scope.row.sms_handle === 0" type="warning" size="mini" @click="handleSmsHandle(scope.row)">处理</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    school_id: '',
                    order_no: '',
                    u_no: '',
                    phone: '',
                    put_meal_type: '',
                    device_name: '',
                    state: '',
                    start_time: '',
                    end_time: '',
                },
            };
        },
        created() {
            this.getList();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"])
        },
        watch: {
            school_id(newVal, oldVal) {
                this.listQuery.school_id = newVal;
                this.getList();
            },
        },
        methods: {
            getList() {
                this.listQuery.school_id = this.school_id;
                this.listLoading = true;
                request({
                    url: "/api/backend/order/sideboardOrderList",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            handleSmsHandle(row) {
                this.$confirm('确认继续该操作？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    request({
                        url: '/api/backend/order/sideboardSmsHandle',
                        method: 'post',
                        data: {sideboard_order_no: row.sideboard_order_no}
                    }).then(()=>{
                        this.getList()
                        this.$message({
                            type: 'success',
                            message: '操作成功'
                        });
                    })
                }).catch(() => {

                });
            },
        }
    };
</script>

<style rel="stylesheet/scss" lang="scss">
    .custom-inline-form {
        margin-bottom: 10px;
        .el-row {
            padding: 4px 0;
            font-weight: bold;
        }
        .el-form-item {
            margin-bottom: 4px !important;
        }
        .el-form-item__label {
            line-height: 20px !important;
        }
        .el-form-item__content {
            line-height: 20px !important;
        }
    }
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
</style>
